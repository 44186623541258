import Vue from 'vue';
import App from './App.vue'
import './plugins/element'
import router from './router';
import store from './store';
import { doGet, doPost, doPut, doDelete } from './plugins/http';
import { getCookie, setCookie, downFile } from "./plugins/util";
import './style.css';
import './assets/iconfont/iconfont.css';
// import './element-variables.scss'
Vue.config.productionTip = false
Vue.prototype.$get = doGet;
Vue.prototype.$post = doPost;
Vue.prototype.$put = doPut;
Vue.prototype.$delete = doDelete;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$setCookie = setCookie;
Vue.prototype.$downFile = downFile;
new Vue({
    router,
    store,
    render: h => h(App),
    async created() {}
}).$mount('#app');