//读取cookies
export function getCookie(name) {
    let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    let arr = document.cookie.match(reg);
    if (arr)
        // return decodeURI(unescape(arr[2]));
        return arr[2];
    else
        return null;
}

//设置cookie: cookie名，cookie值，小时
export function setCookie(name, value, days = 8, domain = '.zhihuishu.com') {
    let now = new Date();
    let timestamp = new Date(now.setHours(now.getHours() + days));//小时
    document.cookie = name + '=' + value + '; expires=' + timestamp.toGMTString() + '; domain=' + domain;
}

//删除cookie
export function removeCookie(name, domain) {
    setCookie(name, '', -1);
}

export function getLoginUUId() {
    if (!getCookie("CASLOGC")) {
        return null;
    }
    let caslogc = JSON.parse(decodeURIComponent(getCookie("CASLOGC")));
    return caslogc['uuid'];
}

export function getLoginUserId() {
    if (!getCookie("CASLOGC")) {
        return null;
    }
    let caslogc = JSON.parse(decodeURIComponent(getCookie("CASLOGC")));
    return caslogc['userId'];
}

export function getLoginRealName() {
    if (!getCookie("CASLOGC")) {
        return null;
    }
    let caslogc = JSON.parse(decodeURIComponent(getCookie("CASLOGC")));
    return caslogc['realName'];
}

export function getLoginUserName() {
    if (!getCookie("CASLOGC")) {
        return null;
    }
    let caslogc = JSON.parse(decodeURIComponent(getCookie("CASLOGC")));
    // console.log(caslogc)
    return caslogc['username'];
}

export function getLoginUserUrl() {
    if (!getCookie("CASLOGC")) {
        return null;
    }
    let caslogc = JSON.parse(decodeURIComponent(getCookie("CASLOGC")));
    return caslogc['headPic'];
}
