<template>
  <div id="app">
    <router-view :key="$route.path"  v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  created(){
  },
  provide (){
    return {
      reload:this.reload
    }
  },
  data(){
    return {
      isRouterAlive:true
    }
  },
  methods:{
    reload (){
      console.log('刷新');
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    }
  },
  components:{
  }

};
</script>