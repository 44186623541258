import axios from 'axios';
import { Message } from 'element-ui';
import { Loading } from "element-ui";
import { getCookie,setCookie,removeCookie,getLoginUUId,getLoginUserId,getLoginRealName,getLoginUserName,getLoginUserUrl } from "../plugins/cookie";
import router from './../router';

const service = axios.create({
    baseURL: 'https://ylbk-api.zhihuishu.com/api/v1',
    timeout: 30 * 1000
});

// 请求拦截器
let loadingInstance = null;
service.interceptors.request.use(config => {
    loadingInstance = Loading.service({ fullscreen: true });
    config.data = JSON.stringify(config.data);
    let domain = window.location.hostname;
    if (domain === 'localhost') {
        config.baseURL = 'https://ylbk-api.zhihuishu.com/api/v1';
    }
    if (domain === '127.0.0.1' || domain === '103.21.142.38') {
        config.baseURL = 'http://103.21.142.38:55001/api/v1';
    }
    config.headers = {
        'Content-Type': 'application/json',
    }
    const token = '123456';
    const context = router.currentRoute.params.context;
    const loginUserId = getLoginUserId();
    const loginUUId = getLoginUUId();
    const app = "web";
    token && (config.headers.token = token);
    context && (config.headers.context = context);
    loginUserId && (config.headers.loginUserId = loginUserId);
    loginUUId && (config.headers.loginUUId = loginUUId);
    app && (config.headers.app = app);
    return config
}, error => {
    Promise.reject(error)
});

// 响应拦截器
service.interceptors.response.use(({ data: { code, data, message } = {} }) => {
    loadingInstance.close();
    if (code === '000') {
        return Promise.resolve(data);
    }
    Message.error(message || '未知错误！');
    return Promise.reject({ code, message });
}, error => {
    loadingInstance.close();
    // 异常处理
    if (error && error.response) {
        //公共错误处理
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break;
            case 401:
                error.message = '未授权，请重新登录'
                break;
            case 403:
                error.message = '拒绝访问'
                break;
            case 404:
                error.message = '请求错误,未找到该资源'
                break;
            case 405:
                error.message = '请求方法未允许'
                break;
            case 408:
                error.message = '请求超时'
                break;
            case 500:
                error.message = error.response.message || '服务器端出错'
                break;
            case 501:
                error.message = '网络未实现'
                break;
            case 502:
                error.message = '网络错误'
                break;
            case 503:
                error.message = '服务不可用'
                break;
            case 504:
                error.message = '网络超时'
                break;
            case 505:
                error.message = 'http版本不支持该请求'
                break;
            default:
                error.message = `连接错误${error.response.status}`
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            Message.error('服务器响应超时，请刷新当前页')
        }
    }
    Message.error(error.message)
    return Promise.reject(error.response)
});

export function doGet(url, params) {
    const config = {
        method: 'get',
        url
    }
    if (params) config.params = params
    return service(config);
}

export function doPost(url, params) {
    const config = {
        method: 'post',
        url
    }
    if (params) config.data = params
    return service(config);
}

export function doPut(url, params) {
    const config = {
        method: 'put',
        url
    }
    if (params) config.data = params
    return service(config);
}

export function doDelete(url, params) {
    const config = {
        method: 'delete',
        url
    }
    if (params) config.params = params
    return service(config);
}
