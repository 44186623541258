import Vue from 'vue'
import {
    Button,
    Tag,
    Container,
    Header,
    Footer,
    Main,
    Menu,
    MenuItem,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Select,
    Option,
    Input,
    InputNumber,
    DatePicker,
    Table,
    TableColumn,
    Pagination,
    Step,
    Steps,
    Tabs,
    TabPane,
    ColorPicker,
    Upload,
    Dialog,
    Radio,
    RadioGroup,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Col,
    TimePicker,
    Row,
    Message,
    MessageBox,
    Popover,
    Carousel,
    CarouselItem,
    Card,
    Avatar,
    Image,
    Tooltip
} from 'element-ui';

Vue.use(Button);
Vue.use(Tag);
Vue.use(Container);
Vue.use(Header);
Vue.use(Footer);
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(ColorPicker);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Card);
Vue.use(Avatar);
Vue.use(Image);
Vue.use(Tooltip);
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message; // 消息提示