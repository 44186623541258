import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
const routes = [{
    path: '/:context',
    component: () => import ( /* webpackChunkName: "home" */ './pages/home/Index.vue'),
    meta: { title: '学校主页' },
}, {
    // 一流课程
    path: '/:context/first-class-course',
    meta: {
        origin: 'undergraduate',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "first-class-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 申报课程
    path: '/:context/declaration-course',
    meta: {
        origin: 'declaration',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "declaration-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 其他课程1
    path: '/:context/others1-course',
    meta: {
        origin: 'others1',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "others-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 其他课程2
    path: '/:context/others2-course',
    meta: {
        origin: 'others2',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "others-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 其他课程1
    path: '/:context/others3-course',
    meta: {
        origin: 'others3',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "others-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 其他课程1
    path: '/:context/others4-course',
    meta: {
        origin: 'others4',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "others-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 其他课程1
    path: '/:context/others5-course',
    meta: {
        origin: 'others5',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "others-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 其他课程1
    path: '/:context/others6-course',
    meta: {
        origin: 'others6',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "others-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 其他课程1
    path: '/:context/others7-course',
    meta: {
        origin: 'others7',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "others-course" */ './pages/course/courseList/Index.vue'),
}, {
    // 其他课程1
    path: '/:context/others8-course',
    meta: {
        origin: 'others8',
        title: '学校主页'
    },
    component: () => import ( /* webpackChunkName: "others-course" */ './pages/course/courseList/Index.vue'),
}, {
    path: '/:context/course-view/:id/:catalog',
    component: () => import ( /* webpackChunkName: "home" */ './pages/course/courseView/Index.vue'),
    meta: { title: '学校主页' },
}, {
    path: '/:context/news',
    component: () => import ( /* webpackChunkName: "home" */ './pages/news/Index.vue'),
    meta: { title: '学校主页' },
}, {
    path: '/:context/news-detail/:id',
    component: () => import ( /* webpackChunkName: "home" */ './pages/news/detail.vue'),
    meta: { title: '学校主页' },
}, {
    path: '/:context/banner-detail/:id',
    component: () => import ( /* webpackChunkName: "home" */ './pages/news/bannerDetail.vue'),
    meta: { title: '学校主页' },
}, {
    path: '/:context/policy',
    component: () => import ( /* webpackChunkName: "home" */ './pages/policy/Index.vue'),
    meta: { title: '学校主页' },
}, {
    path: '/:context/teacher',
    component: () => import ( /* webpackChunkName: "teacher" */ './pages/teacher/Index.vue'),
    meta: { title: '学校主页' },
}, {
    path: '/:context/teacherview/:id',
    component: () => import ( /* webpackChunkName: "teacher" */ './pages/teacher/Detail.vue'),
    meta: { title: '学校主页' },
}];
const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    const breadCrumb = to.matched.filter(({ name }) => !!name).map(({ name, path }) => ({ name, path }));
    store.commit('setBreadCrumb', breadCrumb);
    next();
});
// router.afterEach((to,from,next)=>{
//     console.log(store.getters.count)
//     next();
// });
Vue.use(VueRouter);
export default router;
export { routes }