import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        count: 0,
        breadCrumb: [],
        userInfo: {},
        themeColor:'#5676DC',
        tabs: [{
            menuId: 9,
            name: '学校首页',
            path: '/',
            icon: 'iconshangchuantouxiang2x'
        }, {
            menuId: 10,
            name: '一流课程',
            path: '/first-class-course',
            icon: 'iconyiliubenkekecheng'
        }, {
            menuId: 11,
            name: '申报课程',
            path: '/declaration-course',
            icon: 'iconkechengleixing'
        }, {
            menuId: 29,
            name: '其他课程1',
            path: '/others1-course',
            icon: 'iconshuokeshipin'
        }, {
            menuId: 30,
            name: '其他课程2',
            path: '/others2-course',
            icon: 'iconshuokeshipin'
        }, {
            menuId: 33,
            name: '其他课程3',
            path: '/others3-course',
            icon: 'iconshuokeshipin'
        }, {
            menuId: 34,
            name: '其他课程4',
            path: '/others4-course',
            icon: 'iconshuokeshipin'
        }, {
            menuId: 37,
            name: '其他课程5',
            path: '/others5-course',
            icon: 'iconshuokeshipin'
        }, {
            menuId: 38,
            name: '其他课程6',
            path: '/others6-course',
            icon: 'iconshuokeshipin'
        }, {
            menuId: 41,
            name: '其他课程7',
            path: '/others7-course',
            icon: 'iconshuokeshipin'
        }, {
            menuId: 42,
            name: '其他课程8',
            path: '/others8-course',
            icon: 'iconshuokeshipin'
        }, {
            menuId: 18,
            name: '政策文件',
            path: '/policy',
            icon: 'iconzhengcewenjian'
        }, {
            menuId: 17,
            name: '新闻动态',
            path: '/news',
            icon: 'iconshangchuantouxiang2x'
        }, {
            menuId: 26,
            name: '名师风采',
            path: '/teacher',
            icon: 'iconshangchuantouxiang2x'
        }]
    },
    mutations: {
        setBreadCrumb(state, breadCrumb) {
            state.breadCrumb = breadCrumb;
        },
        mergeToState(state, data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const item = data[key];
                    console.debug(item);
                    state[key] = data[key]
                }
            }
            // state = { ...state, ...data };
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        }
    },
    actions: {
        asyncMergeToState({ commit }, data) {
            commit('mergeToState', data);
        },
        setUserInfoAction({ commit }, userInfo) {
            commit('setUserInfo', userInfo);
        }
    }
})

export default store;